// All information about me
import PayPal from "./../public/PayPal.png";
import icsImage from "./../public/ics.jpeg";
import cuImage from "./../public/cuboulder.png";
import medibuddyImage from "./../public/medibuddy_logo.jpeg";
import semusiImage from "./../public/semusi.png";
import ramaiahImage from "./../public/ramaiah.png";

export const timeline = [
  {
    image: PayPal,
    organization: "PayPal",
    employmentType: "Internship",
    time: "May 2024 - August 2024",
    position: "Software Engineer Intern",
    description:
      "Collaborated with the checkout team to develop the Session Explorer tool, enhancing user checkout experience and boosted team productivity. The tool processed over a million transactions per second accross the globe using Spring Boot, Node.js, JunoDB, and React.",
    location: "San Jose, CA",
    link: "https://www.paypal.com/",
  },
  {
    image: icsImage,
    organization: "Institute of Cognitive Science (CU Boulder)",
    employmentType: "Part-time",
    time: "Sep 2023 - Present",
    position: "Software Engineer",
    description:
      "Working on the development of an application for the NSF funded project, 'CoBi'. The project aims to develop a model that can help students learn and grow in a collaborative environment.",
    location: "Boulder, CO",
    link: "https://www.colorado.edu/research/ai-institute/tilak-singh",
  },
  {
    image: cuImage,
    organization: "University of Colorado Boulder",
    employmentType: "Full-time",
    position: "Graduate Student",
    time: "Aug 2023 - May 2025",
    description:
      "Pursuing a MS in Computer Science with a perfect GPA of 4.0. Relevant coursework includes AI in computer systems, Datacenter Scale computing, Computer Vision, Deep Learning, Object-oriented analysis and design and Advanced algorithms.",
    location: "Boulder, CO",
    link: "https://www.colorado.edu/cs/",
  },
  {
    image: medibuddyImage,
    organization: "MediBuddy",
    employmentType: "Full-time",
    time: "May 2021 - Jul 2022",
    position: "Software Development Engineer",
    description:
      "Worked as the full-stack developer for the 'MediBuddy' app. The app aims to provide a one-stop solution for all healthcare needs. ",
    location: "Bangalore, India",
    link: "https://www.medibuddy.in/aboutus/",
  },
  {
    image: semusiImage,
    organization: "Semusi Technologies Pvt. Ltd.",
    employmentType: "Full-time",
    time: "Mar 2021 - May 2021",
    position: "Backend Engineer Intern",
    description:
      "Contributed to the backend development of the flagship product, appICE.",
    location: "Remote",
    link: "https://www.appice.io/",
  },
  {
    image: ramaiahImage,
    organization: "MS Ramaiah Instutue of Technology",
    employmentType: "Full-time",
    position: "Undergraduate Student",
    description:
      "Graduated with a Bachelor's degree in Information Science and Engineering with a CGPA: 8.41/10. Relevant coursework includes Data Structures, Algorithms, Operating Systems, Database Management Systems, etc.",
    time: "Aug 2017 - Aug 2021",
    location: "Bangalore, India",
    link: "https://www.msrit.edu/",
  },
];

export const profileLinks = {
  LinkedIn: {
    name: "LinkedIn",
    link: "https://www.linkedin.com/in/tilak-singh",
  },
  Github: {
    name: "Github",
    link: "https://github.com/is124",
  },
  Twitter: {
    name: "Twitter",
    link: "https://twitter.com/tilaks_11",
  },
};

export const skills = {
  "Languages 🧑🏻‍💻": [
    "C",
    "Java",
    "Python",
    "JavaScript",
    "TypeScript",
    "SQL",
    "HTML5",
    "CSS",
  ],
  "Frameworks 🧮": [
    "SpringBoot",
    "Express.js (Node.js)",
    "React.js",
    "Angular",
    "Mocha",
    "JUnit",
    "TensorFlow",
    "Keras",
    "PyTorch",
    "Hadoop",
    "Spark",
  ],
  "Tools 🛠️": [
    "Redis",
    "MongoDB",
    "MySQL",
    "AWS",
    "GCP",
    "Cloudflare",
    "Jenkins",
    "Unix Shell",
    "Webpack",
    "Git",
    "Kibana",
    "Kafka",
    "Docker",
    "Kubernetes",
    "Postman",
    "VertexAI",
  ],
  "Others 👾": [
    "DSA",
    "OOPs",
    "Design Patterns",
    "REST APIs",
    "Microservices",
    "Agile Methodologies",
    "Test Driven Development",
    "WebSockets",
    "API Gateways",
    "CI/CD",
    "Agile Methodologies",
  ],
};

export const projects = [
  {
    name: "AI Cost Estimation",
    description:
      "Collaborating under the guidance of Prof. Eric Keller on a project focused on estimating the cost of deploying AI models. The project integrates machine learning and operational workflows, involving MLOps for efficient management.",
    link: "",
    techStack: [
      "Python",
      "TensorFlow",
      "Keras",
      "Ray",
      "Prometheus",
      "PyTorch",
      "Docker",
      "Kubernetes",
      "Vertex AI",
    ],
  },
  {
    name: "Cafè Connect",
    description:
      "Designed and developed a college dining enhancement web app with a Spring Boot backend, React.js frontend, and utilized modular design patterns, MySQL, and CockroachDB for scalability.",
    link: "",
    techStack: [
      "SpringBoot",
      "React.js",
      "MySQL",
      "CockroachDB",
      "Git",
      "Intelij IDEA",
      "VSCode",
    ],
  },
  {
    name: "Dynamo",
    description:
      "Full-stack web monitoring application that enables users to comprehensively track and analyze their website's performance, conducting continuous assessments of availability.",
    link: "https://github.com/is124/Dynamo",
    techStack: ["Node.js", " React.js", "HTML", "MySQL", "Git", "VSCode"],
  },
  {
    name: "VoLo (Vocal for Local)",
    description: "A full-stack e-commerce project for local vendors",
    link: "https://theindiancraftstore.netlify.app/",
    techStack: [
      "Node.js",
      "React.js",
      "MySQL",
      "Nodemailer",
      "Bitbucket",
      "VSCode",
    ],
  },
  {
    name: "Sankalp Library",
    description:
      "SPA Designed and developed for client’s library web application",
    link: "https://www.sankalplibrary.com/",
    techStack: [
      "React.js",
      "SpringBoot",
      "AWS Amplify",
      "Google domains",
      "Git",
      "VSCode",
      "IntelliJ IDEA",
    ],
  },
];

export const research = [
  {
    title:
      "Assessment and prediction of PM2.5 in Delhi in view of stubble burn from border states using Collaborative Learning Model",
    journal: "Aerosol Science and Engineering",
    publishedDate: "17th November 2020",
    link: "https://link.springer.com/article/10.1007/s41810-020-00083-1",
  },
  {
    title: "Data collection and processing in health care.",
    journal:
      "Artificial Intelligence for Information Management: A Healthcare Perspective",
    publishedDate: "21st May 2021",
    link: "https://link.springer.com/chapter/10.1007/978-981-16-0415-7_4",
  },
];
