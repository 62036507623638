import React from "react";
import "../style/About.css";
// import Banner from "../component/Banner";
import { skills } from "../utils/info";
import useScrollToTop from "../utils/useScrollToTop";

const About = () => {
  useScrollToTop();

  return (
    <>
      {/* <Banner /> */}
      <div className="about-container">
        <div className="content-container">
          <div className="profile-picture"></div>
          <div className="bio">
            <h1 style={{ padding: "5px" }}>
              Hi, I'm <strong>Tilak Singh</strong>! 👋
            </h1>
            <div className="description">
              <p>
                I'm a graduate student at the{" "}
                <b>University of Colorado, Boulder</b>, pursuing my M.S. in
                Computer Science. <b>SKO! BUFFS 🦬</b>
              </p>
              <p>
                I completed my bachelor's in engineering from India, majoring in
                Information Science and Engineering. I have since worked as a
                full-time <b>Software Development Engineer</b> at a fast-paced
                health-tech industry, where I engineered the application that
                serves over <b>10 million users</b> across iOS, Android, and web
                platforms, and Interned with <b>PayPal</b> as a{" "}
                <b> Software Engineer Intern</b> in the summer of 2024.
              </p>
              <p>
                I'm deeply passionate about building and designing large
                scalable systems. I consistently approach both personal and
                professional projects with a scalability-focused mindset, aiming
                to optimize performance and ensure smooth, sustainable growth.
              </p>
            </div>
          </div>
        </div>

        <div className="skill-container">
          <center>{/* <h1>Skills</h1> */}</center>
          <div className="skill-list">
            {Object.entries(skills).map(([category, skillsList]) => (
              <div className="skill-column" key={category}>
                <div className="skill-card">
                  <h2>{category}</h2>
                  <ul>
                    {skillsList.map((skill, index) => (
                      <li key={index}>{skill}</li>
                    ))}
                  </ul>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default About;
